import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import React from "react"
import useStations from "../utils/hooks/useStations"
const BecomePartnerPage = () => {
  const { station } = useStations()
  return (
    <Layout station={station}>
      <Seo title="Become a Partner" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <h1>Become a Partner</h1>

            <p className="intro">
              Partner with NEWA to enhance your IPM and crop management
              decision-making.
            </p>

            <p>
              NEWA is a partnership of land grant universities and grower
              associations. If you live in one of the states listed, you can{" "}
              <Link to="/buy-a-weather-station">buy a weather station</Link> for
              your farm and connect to NEWA. Or visit our{" "}
              <Link to="/partners">Partners</Link> page to find out if you are
              in a NEWA Partner state.
            </p>

            <hr></hr>
            <h2>NEWA in New York State</h2>
            <div className="p-4 mb-10 lg:p-6 bg-light-200">
              <p>
                The New York State Coordinator is listed here. Contact this
                person with questions about NEWA tools and resources in New York
                or find your NEWA State Coordinator our{" "}
                <Link to="/partners">Partners</Link> page.
              </p>

              <div className="pl-4 mb-10 border-l-2 lg:pl-6">
                <h3>Dan Olmstead</h3>
                <p>
                  New York State IPM Program<br></br>
                  Cornell University<br></br>
                  Geneva, NY 14456<br></br>
                  315-787-2207<br></br>
                  <a href="mailto:dlo6@cornell.edu?subject=NEWA%20weather%20station%20inquiry">
                    dlo6@cornell.edu
                  </a>
                </p>
              </div>
            </div>

            <h2>Top reasons NEWA benefits my state:</h2>

            <ol>
              <li>
                Better IPM, reduced pesticide use, improved environmental
                protection.
              </li>
              <li>
                Better crop management, improved crop quality, improved yield.
              </li>
              <li>Enhanced decision support.</li>
            </ol>

            <hr></hr>

            <h2>Become a NEWA Partner State</h2>

            <p>
              If you aren’t in a NEWA Partner state, contact the{" "}
              <a href="mailto:support@newa.zendesk.com">NEWA Help Desk</a>, to
              learn about the benefits gained from statewide membership.
              Extension faculty and growers that know about the positive
              outcomes and impacts of using NEWA, want NEWA coverage in their
              geographic regions. Read{" "}
              <a
                href="https://live-cu-newa.pantheonsite.io/wp-content/uploads/ExpansionDocument4NEWA_2020Feb.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                details and expectations about the NEWA partnership
              </a>
              .
            </p>

            <hr></hr>

            <h2>Become a Weather Station Owner</h2>

            <p>
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">Onset Data Loggers</Link> and <Link to="https://kestrelmet.com">KestrelMet</Link> offer weather stations
              configured for NEWA’s crop and IPM tools. Visit our{" "}
              <Link to="/buy-a-weather-station">Buy a Weather Station</Link>{" "}
              page to contact vendors and understand the steps required for
              connecting with NEWA resources. If you are in a Partner state, an
              unlimited number of weather stations can be added to NEWA in that
              state. If you are outside the NEWA network, you can become a
              Partner Grower, read more below.
            </p>

            <hr></hr>

            <h2>Become a NEWA Partner Grower</h2>

            <p>
              Not in a NEWA Partner state? You can still join NEWA for an annual
              membership fee of $290 for the first weather station. Add more
              weather stations on your farm at a pro-rated fee of $260 each.
              Learn more{" "}
              <Link to="/about-weather-stations">About Weather Stations</Link>{" "}
              that can connect to NEWA. Read{" "}
              <a
                href="https://live-cu-newa.pantheonsite.io/wp-content/uploads/ExpansionDocument4NEWA_2020Feb.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                details and expectations about the NEWA partnership
              </a>
              .
            </p>

            <footer className="pt-6 mt-12 text-base border-t border-gray-200">
              <p>Page updated May 2020 by D. Olmstead and J. Carroll</p>
            </footer>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
          <h2>Get a Weather Station</h2>
            <p>
              NEWA is compatible with special configurations of {' '}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset 
              </Link>{' '}
              and <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">KestrelMet</Link> weather instruments. See what your options are.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                to="/buy-a-weather-station"
              >
                Buy a Weather Station
              </Link>
            </p>

            <hr></hr>

            <h2>Have a question?</h2>
            <p>
              Reach out to our support center and ask questions to get support for NEWA tools, resources, and weather
              stations.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Contact the NEWA Help Desk
              </a>
            </p>

            <hr></hr>

            <h2>Become a Partner</h2>
            <p>
              NEWA is a partnership of land grant universities and grower
              associations. If you live in a partner state, you can{" "}
              <Link to="/buy-a-weather-station">buy a weather station</Link> for
              your farm and connect to NEWA.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Learn More About Becoming a Partner"
                to="/become-partner"
              >
                Learn More
              </Link>
            </p>
            <hr></hr>
            <h2>NEWA Partners</h2>
            <ul>
              <li>
                <a
                  href="http://www.cornell.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cornell University
                </a>
              </li>
              <li>
                <a
                  href="http://www.msu.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Michigan State University
                </a>
              </li>
              <li>
                <a
                  href="http://www.minnesotaapple.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Minnesota Apple Growers Assoc.
                </a>
              </li>
              <li>
                <a
                  href="http://www.ncapplegrowers.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  North Carolina Apple Growers Assoc.
                </a>
              </li>
              <li>
                <a
                  href="http://cfaes.osu.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Ohio State University
                </a>
              </li>
              <li>
                <a
                  href="http://www.psu.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pennsylvania State University
                </a>
              </li>
              <li>
                <a
                  href="http://www.rutgers.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rutgers University
                </a>
              </li>
              <li>
                <a
                  href="http://uconn.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Connecticut
                </a>
              </li>
              <li>
                <a
                  href="http://ag.umass.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Massachusetts
                </a>
              </li>
              <li>
                <a
                  href="http://www.unh.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of New Hampshire
                </a>
              </li>
              <li>
                <a
                  href="http://www.uvm.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Vermont
                </a>
              </li>
              <li>
                <a
                  href="https://www.wisc.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  University of Wisconsin-Madison
                </a>
              </li>
              <li>
                <a
                  href="http://www.usu.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Utah State University
                </a>
              </li>
              <li>
                <a
                  href="https://www.vt.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Virginia Tech
                </a>
              </li>
              <li>
                <a
                  href="https://www.extension.wvu.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  West Virginia University Extension Service
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BecomePartnerPage
